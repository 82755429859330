export const getSubDomain = () => {
  const url = window.location.host; // window.location.host is "subdomain.domain.com"
  const parts = url.split('.');
  const sub = parts[0] !== 'www' ? parts[0] : parts[1]; // "subdomain"
  return sub;
};

// eslint-disable-next-line
export const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export const htmlRegex = /<[^>]*>/;

export const removeUrlParam = (key, sourceURL) => {
  var rtn = sourceURL.split('?')[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    params_arr = queryString.split('&');
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + '?' + params_arr.join('&');
  }
  return rtn;
};

// return last 4 characters from the string
export const getLastFourChar = (stringValue) => {
  return stringValue.substring(stringValue.length - 4, stringValue.length);
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const undefinedCheck = (data, defaultObjectValue = false) => {
  if (data === undefined) {
    return defaultObjectValue;
  } else {
    return data;
  }
};

export const getImageDisplayType = (clientId, defaultDisplay = 'Square') => {
  const arrVerticalImageClients = [87];
  const checkValue = (value) => {
    return arrVerticalImageClients.includes(value);
  };
  return checkValue(clientId) ? 'Vertical' : 'Square';
};

export const isImageHasBackground = (clientId) => {
  const arrVerticalImageClients = [109, 204];
  const checkValue = (value) => {
    return arrVerticalImageClients.includes(value);
  };
  return checkValue(clientId) ? true : false;
};

// function to check if data present in an array of objects
export const isDataPresent = (arr, objKey) => {
  for (let i = 0; i < arr?.length; i++) {
    if (arr[i][objKey] !== 0) {
      return true;
    }
  }
  return false;
};
export const camelCaseFirstLetters = (str) => {
  if (str) {
    const words = str.split(' ');
    const firstLetter = words[0][0]; // First letter of the first word
    const lastLetter = words[words.length - 1][0] ?? words[words.length - 2][0]; // First letter of the last word, sometimes last word is just a space take the last second word
    return firstLetter + lastLetter;
  } else return '';
};

// check if value is an object type
export const isObject = (objValue) => {
  let bool = false;
  if (
    objValue &&
    typeof objValue === 'object' &&
    objValue.constructor === Object
  ) {
    bool = true;
  }
  return bool;
};

// generate chart colors
export const getChartColors = (max) => {
  let colors = [];
  for (let i = 0; i < max; i++) {
    colors.push('#' + Math.random().toString(16).substr(-6));
  }
  return colors;
};

// check if 2 objects values are equal
export const areObjectsEqual = (objA, objB) => {
  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  return keysA.every((key) => objA[key] === objB[key]);
};
// returns filename without extension
export function formatFilename(filename) {
  return filename
    .replace(/\.[^.]+$/, '') // Remove file extension
    .replace(/\s+/g, '') // Remove spaces
    .toLowerCase(); // Convert to lowercase
}
