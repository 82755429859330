import {
  api,
  apiEndPoints,
  addActionLoader,
  removeActionLoader,
} from '../../api/index';
import { showSnackbar } from '../../components/snackbar/actions';
import {
  snackbarTypes,
  infoModalTypes,
  defaultMessages,
} from '../../constants';
import { showInfoModal } from 'components/info-modal/actions';

const namespace = 'pages/customers/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const GET_RETAILER_REQUEST = `${namespace}GET_RETAILER_REQUEST`;
export const GET_BUYER_REQUEST = `${namespace}GET_BUYER_REQUEST`;
export const GET_RETAILER_DETAILS_REQUEST = `${namespace}GET_RETAILER_DETAILS_REQUEST`;
export const ADD_BILLING_ADDRESS = `${namespace}ADD_BILLING_ADDRESS`;
export const EDIT_BILLING_ADDRESS = `${namespace}EDIT_BILLING_ADDRESS`;
export const DELETE_BILLING_ADDRESS = `${namespace}DELETE_BILLING_ADDRESS`;
export const ADD_SHIPPING_ADDRESS = `${namespace}ADD_SHIPPING_ADDRESS`;
export const EDIT_SHIPPING_ADDRESS = `${namespace}EDIT_SHIPPING_ADDRESS`;
export const DELETE_SHIPPING_ADDRESS = `${namespace}DELETE_SHIPPING_ADDRESS`;
export const ADD_PAYMENT_TYPE = `${namespace}ADD_PAYMENT_TYPE`;
export const GET_ADD_PAYMENT_TYPE_FIELDS = `${namespace}GET_ADD_PAYMENT_TYPE_FIELDS`;
export const EDIT_PAYMENT_TYPE = `${namespace}EDIT_PAYMENT_TYPE`;
export const DELETE_PAYMENT_TYPE = `${namespace}EDIT_PAYMENT_TYPE`;
export const DELETE_RETAILER = `${namespace}DELETE_RETAILER`;
export const GET_COUNTRY_OPTIONS = `${namespace}GET_COUNTRY_OPTIONS`;
export const ADD_BUYER_REQUEST = `${namespace}ADD_BUYER_REQUEST`;
export const CHECK_BUYER_USER_NAME_REQUEST = `${namespace}CHECK_BUYER_USER_NAME_REQUEST`;
export const DELETE_BUYER = `${namespace}DELETE_BUYER`;
export const EDIT_BUYER = `${namespace}EDIT_BUYER`;
export const DECLINE_RETAILER_REQUEST = `${namespace}DECLINE_RETAILER_REQUEST`;
export const GET_GROUPS = `${namespace}GET_GROUPS`;
export const SORT_GROUPS = `${namespace}SORT_GROUPS`;
export const CREATE_GROUPS = `${namespace}CREATE_GROUPS`;
export const EDIT_GROUPS = `${namespace}EDIT_GROUPS`;
export const DELETE_GROUPS = `${namespace}DELETE_GROUPS`;
export const ADD_NOTES = `${namespace}ADD_NOTES`;
export const EDIT_NOTES = `${namespace}EDIT_NOTES`;
export const DELETE_NOTES = `${namespace}DELETE_NOTES`;
export const CHECK_ORDERS_PRESENT = `${namespace}CHECK_ORDERS_PRESENT`;
export const CHECK_BILLING_ADDRESS_DELETE = `${namespace}CHECK_BILLING_ADDRESS_DELETE`;
export const CHECK_BUYER_DELETE = `${namespace}CHECK_BUYER_DELETE`;
export const CHECK_SHIPPING_ADDRESS_DELETE = `${namespace}CHECK_SHIPPING_ADDRESS_DELETE`;
export const CHECK_GROUP_NAME = `${namespace}CHECK_GROUP_NAME`;
export const BUYER_REQUEST_REQUEST = `${namespace}BUYER_REQUEST_REQUEST`;
export const ADD_RETAILER_REQUEST = `${namespace}ADD_RETAILER_REQUEST`;
export const EDIT_RETAILER_REQUEST = `${namespace}EDIT_RETAILER_REQUEST`;
export const GET_MY_RETAILER_DETAILS_REQUEST = `${namespace}GET_MY_RETAILER_DETAILS_REQUEST`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

// export const startLoader = (type) => async (dispatch) => {
//   dispatch(addActionLoader(type));
// };

// export const stopLoader = (type) => async (dispatch) => {
//   dispatch(removeActionLoader(type));
// };

export const getRetailer = (successCallBack, payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_RETAILER_REQUEST`));

    const response = await api.get(
      `${apiEndPoints.myRetailers}?search=${payload}`
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return successCallBack(data);
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_RETAILER_REQUEST));
  }
};

export const getBuyers = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_BUYER_REQUEST`));

    const response = await api.get(
      `${apiEndPoints.buyerList}?page=${payload.pageNumber}&limit=${payload.limit}&search=${payload.globalSearchValue}`
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data.data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_BUYER_REQUEST));
  }
};
export const getRetailerDetail =
  (successCallBack, payload) => async (dispatch) => {
    try {
      dispatch(addActionLoader(GET_RETAILER_DETAILS_REQUEST));

      const response = await api.get(apiEndPoints.retailerDetail(payload));
      const { data } = response;

      if (response.status === 200 || data.status) {
        return successCallBack(data.data);
      }
      return data;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(GET_RETAILER_DETAILS_REQUEST));
    }
  };
//  Add new Billing Address
export const postAddBillingAddress = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_BILLING_ADDRESS));
    const response = await api.post(apiEndPoints.newAddBillingRequest, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_BILLING_ADDRESS));
  }
};
//  edit  Billing Address
export const editBillingAddress = (payload, id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EDIT_BILLING_ADDRESS));
    const response = await api.put(
      apiEndPoints.editBillingRequest(id),
      payload
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EDIT_BILLING_ADDRESS));
  }
};
//  delete Billing Address
export const deleteBillingAddress = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_BILLING_ADDRESS));
    const response = await api.delete(
      apiEndPoints.deleteBillingAddress(payload)
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_BILLING_ADDRESS));
  }
};
//  Add new Shipping Address
export const postAddShippingAddress = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_SHIPPING_ADDRESS));
    const response = await api.post(apiEndPoints.newShippingRequest, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_SHIPPING_ADDRESS));
  }
};

//  edit  Shipping Address
export const editShippingAddress = (payload, id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EDIT_SHIPPING_ADDRESS));
    const response = await api.put(
      apiEndPoints.editShippingRequest(id),
      payload
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EDIT_SHIPPING_ADDRESS));
  }
};
//  delete shipping Address
export const deleteAddShippingAddress = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_SHIPPING_ADDRESS));
    const response = await api.delete(
      apiEndPoints.deleteShippingAddress(payload)
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_SHIPPING_ADDRESS));
  }
};
//  add Payment Type
// export const addPaymentType = (payload) => async (dispatch) => {
//   try {
//     dispatch(addActionLoader(ADD_PAYMENT_TYPE));
//     const response = await api.post(
//       apiEndPoints.newPaymentTypeRequest,
//       payload
//     );
//     const { data, data: { status = false, message = '' } = {} } = response;
//     if (status) {
//       dispatch(
//         showSnackbar({
//           snackbarMessage: message,
//           type: snackbarTypes.SUCCESS,
//         })
//       );
//       return data;
//     }
//     dispatch(
//       showSnackbar({
//         snackbarMessage: message,
//         type: snackbarTypes.ERROR,
//       })
//     );
//     return false;
//   } catch (error) {
//     dispatch(setApiError(error));
//     return false;
//   } finally {
//     dispatch(removeActionLoader(ADD_PAYMENT_TYPE));
//   }
// };

//  edit  Payment Type
// export const editPaymentType = (payload, id) => async (dispatch) => {
//   try {
//     dispatch(addActionLoader(EDIT_PAYMENT_TYPE));
//     const response = await api.put(
//       apiEndPoints.editPaymentTypeRequest(id),
//       payload
//     );
//     const { data, data: { status = false, message = '' } = {} } = response;
//     if (status) {
//       dispatch(
//         showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
//       );
//       return data;
//     }
//     dispatch(
//       showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
//     );
//     return false;
//   } catch (error) {
//     dispatch(setApiError(error));
//     return false;
//   } finally {
//     dispatch(removeActionLoader(EDIT_PAYMENT_TYPE));
//   }
// };
//  delete Payment Type
export const deletePaymentType = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_PAYMENT_TYPE));
    const response = await api.delete(apiEndPoints.deletePaymentType(payload));
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_PAYMENT_TYPE));
  }
};
//  get Country Options

export const getCustomerSupportData = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_COUNTRY_OPTIONS));
    const response = await api.get(apiEndPoints.customerSupportData);
    const { data } = response;

    if (response.status === 200 || data.status) {
      return response;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_COUNTRY_OPTIONS));
  }
};
//  add new buyer
export const addNewBuyer = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_BUYER_REQUEST));
    const response = await api.post(apiEndPoints.newAddBuyerRequest, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_BUYER_REQUEST));
  }
};
//  checkbuyer username

export const checkBuyerUserName = (userName) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHECK_BUYER_USER_NAME_REQUEST));
    const response = await api.get(
      apiEndPoints.checkBuyerUserName.concat(`/${userName}`)
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHECK_BUYER_USER_NAME_REQUEST));
  }
};
//  delete buyer
export const deleteBuyer = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_BUYER));
    const response = await api.delete(apiEndPoints.deleteBuyer(payload));
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_BUYER));
  }
};
//  edit buyer
export const editBuyer = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EDIT_BUYER));
    const response = await api.put(apiEndPoints.editBuyerRequest, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EDIT_BUYER));
  }
};
//  decline Retailer request
export const declineRetailerRequest = (payload, id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DECLINE_RETAILER_REQUEST));
    const response = await api.post(
      apiEndPoints.declineRetailerRequest(payload.id)
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DECLINE_RETAILER_REQUEST));
  }
};
//  get Groups
export const getGroups = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_GROUPS`));

    const response = await api.get(
      `${apiEndPoints.groupList}?page=${payload.page}&limit=${
        payload.rowsPerPage
      }&filters={"name":"${
        payload?.groupNameInputField ?? ''
      }","description":"${payload?.groupDescInputField ?? ''}","buyer_count":"${
        payload?.groupBuyerInputField ?? ''
      }","dateRange":["${payload.dateRange?.[0] ?? ''}","${
        payload.dateRange?.[1] ?? ''
      }"]}`
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_GROUPS));
  }
};
//  sort Groups
export const sortGroups = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`SORT_GROUPS`));

    const response = await api.get(
      `${apiEndPoints.groupList}?sortBy=${payload.sort}&sortByColumn=${payload.columnName}&limit=${payload.rowsPerPage}&page=${payload.page}`
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(SORT_GROUPS));
  }
};
//  Create Groups
export const createGroup = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CREATE_GROUPS));
    const response = await api.post(apiEndPoints.groupList, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CREATE_GROUPS));
  }
};
export const checkGroupName = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHECK_GROUP_NAME));
    const response = await api.post(apiEndPoints.checkGroupName, payload);
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return response;
    }
    return response;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHECK_GROUP_NAME));
  }
};
//  Edit Groups
export const editGroup = (payload, id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EDIT_GROUPS));
    const response = await api.put(apiEndPoints.editGroup(id), payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EDIT_GROUPS));
  }
};
//  delete Group
export const deleteGroup = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_GROUPS));
    const response = await api.delete(apiEndPoints.deleteGroup(payload));
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_GROUPS));
  }
};
//  Add Notes
export const addNotes = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_NOTES));
    const response = await api.post(apiEndPoints.retailerNotes, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    response?.data?.errors?.forEach((mess) => {
      Object.keys(mess).forEach((key) => {
        dispatch(
          showSnackbar({
            snackbarMessage: mess[key],
            type: snackbarTypes.ERROR,
          })
        );
      });
    });
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_NOTES));
  }
};
//  edit Notes
export const editNotes = (payload, id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EDIT_NOTES));
    const response = await api.put(apiEndPoints.editRetailerNotes(id), payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    response?.data?.errors?.forEach((mess) => {
      Object.keys(mess).forEach((key) => {
        dispatch(
          showSnackbar({
            snackbarMessage: mess[key],
            type: snackbarTypes.ERROR,
          })
        );
      });
    });
    // dispatch(
    //   showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    // );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EDIT_NOTES));
  }
};
//  delete Notes
export const deleteNotes = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_NOTES));
    const response = await api.delete(
      apiEndPoints.deleteRetailerNotes(payload)
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_NOTES));
  }
};
//  delete Retailer
export const deleteRetailer = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_RETAILER));
    const response = await api.delete(apiEndPoints.deleteRetailers(payload.id));
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(DELETE_RETAILER));
  }
};
//  CHECK IF ORDERS LISTED IN RETAILER
export const checkOrdersListedInRetailer = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHECK_ORDERS_PRESENT));

    const response = await api.get(apiEndPoints.confirmDeleteRetailer(payload));
    const { data } = response;

    if (response.status === 200 || data.status) {
      return response;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHECK_ORDERS_PRESENT));
  }
};
//  CHECK IF BILLING ADDRESS BE DELETED
export const checkBillingAddressBeDeleted = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHECK_BILLING_ADDRESS_DELETE));

    const response = await api.get(
      apiEndPoints.confirmDeleteBillingAddress(payload)
    );
    const { data } = response;

    return data?.status;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHECK_BILLING_ADDRESS_DELETE));
  }
};
//  CHECK IF BUYER CAN BE DELETED
export const checkBuyerBeDeleted = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHECK_BUYER_DELETE));

    const response = await api.get(
      apiEndPoints.confirmDeleteBuyerAddress(payload)
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return response;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHECK_BUYER_DELETE));
  }
};
//  CHECK IF SHIPPING ADDRESS CAN BE DELETED
export const checkShippingAddressBeDeleted = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHECK_SHIPPING_ADDRESS_DELETE));

    const response = await api.get(
      apiEndPoints.confirmDeleteShippingAddress(payload)
    );
    const { data } = response;
    return data?.status;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHECK_SHIPPING_ADDRESS_DELETE));
  }
};

// gets list of pending buyer request action
export const getBuyerRequestsActionsData = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(BUYER_REQUEST_REQUEST));
    const response = await api.get(
      apiEndPoints.getBuyerAddEditRequestNotifications
    );
    const { data, data: { status = false } = {} } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(BUYER_REQUEST_REQUEST));
  }
};

export const addRetailer = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_RETAILER_REQUEST));
    const response = await api.post(apiEndPoints.addEditRetailer, payload);
    const { data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return true;
    } else {
      let concatenatedErrors = '';
      if (response.data?.errors) {
        response.data?.errors?.forEach((item) => {
          Object.keys(item).forEach((key) => {
            const value = item[key];
            if (!key || key.trim() === '') {
              console.log(`Empty key found with value: ${value}`);
            } else if (!value) {
              console.log(`Empty value found for Key: ${key}`);
            }
            // Perform data manipulation with key and value
            concatenatedErrors += `${value}<br/> `;
          });
        });
      }
      dispatch(
        showInfoModal({
          messageTitle: '',
          message: concatenatedErrors ?? defaultMessages.FAILED_TO_EDI_RETAILER,
          type: infoModalTypes.ERROR,
        })
      );
    }

    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_RETAILER_REQUEST));
  }
};

export const editRetailer = (id, payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(EDIT_RETAILER_REQUEST));
    const response = await api.put(
      apiEndPoints.editRetailerRequest(id),
      payload
    );
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(EDIT_RETAILER_REQUEST));
  }
};

export const getMyRetailerDetail = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_MY_RETAILER_DETAILS_REQUEST));

    const response = await api.get(apiEndPoints.myRetailer(id));
    const {
      data: { status = false, data = [] },
    } = response;
    if (status) {
      return data[0];
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_MY_RETAILER_DETAILS_REQUEST));
  }
};
