import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import BackArrowIcon from 'assets/icons/back-arrow-icon';
import Page from 'components/page';
import { useViewModal } from './view-modal-hooks';
import ProductViewSummary from 'components/modals/review-edit-summary/components/product-view-summary';
import ViewSelector from 'components/modals/review-edit-summary/components/view-selector';
import { PrivateScreen } from 'routes/screen-names';
import SessionStorageManager from 'utils/session-storage-manager';
import { responsiveBreakpoints } from 'utils/utils';

const ViewModal = () => {
  const {
    canvas,
    navigate,
    fetchCustomizeProductSummary,
    productCustomizer,
    changeCameraAngle,
    productSumaryData,
    viewOptions,
    customizerDetails,
    productId,
    customizerId,
  } = useViewModal();
  const { mobile } = responsiveBreakpoints();
  return (
    <Page
      fullPage
      backButtonText='Back'
      backButtonHref={SessionStorageManager?.getSessionStorage(
        'backToCustomizer'
      )}
    >
      <Stack
        spacing={1}
        direction='row'
        alignItems='center'
        onClick={(e) => {
          navigate(
            `${PrivateScreen?.customization}?customizerId=${customizerId}&productId=${productId}`
          );
        }}
        sx={[
          {
            marginBottom: '8px',
            cursor: 'pointer',
            width: 'fit-content',
            marginTop: '10px',
          },
        ]}
      >
        <BackArrowIcon />
        <Typography
          variant='body2'
          sx={{
            color: 'var(--link-color)',
            lineHeight: '1.64',
          }}
        >
          Back To Customization
        </Typography>
      </Stack>
      <Box
        id='customizer_container'
        sx={{
          height: mobile ? 'fit-content' : '520px',
          width: '100%',
        }}
      >
        <Grid
          container
          sx={[
            { height: '100%' },
            mobile
              ? {
                  flexDirection: 'column-reverse',
                  rowGap: '16px',
                }
              : null,
          ]}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={[
              {},
              mobile
                ? { maxHeight: '230px', height: '230px' }
                : { height: '100%', paddingTop: '22px' },
            ]}
          >
            <Box
              sx={[
                mobile
                  ? { maxHeight: '230px', height: '230px' }
                  : { height: '100%' },
              ]}
            >
              <ProductViewSummary summaryData={productSumaryData} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <canvas
              ref={canvas}
              style={{
                height: mobile ? '75%' : '84%',
                width: '100%',
                marginBottom: mobile ? '16px' : '24px',
              }}
            />
            <ViewSelector
              viewOptions={viewOptions}
              onSelect={(value) => {
                changeCameraAngle({
                  productCustomizer: productCustomizer,
                  cameraAngle: value?.value,
                });
                fetchCustomizeProductSummary({ cameraAngleId: value?.id });
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default ViewModal;
