import { SET_ACTIVE_DRAFT_SUGGESTION } from './actions/index';
import { SET_DEPARTMENT_LIST } from './actions/index';
import SessionStorageManager from 'utils/session-storage-manager';
import { SET_UPDATE_SUGGESTION_DETAILS } from './actions/index';

const initialState = {
  activeDraftSuggestion: {
    staticData: true,
    draft: {
      id: null,
      name: null,
      productCount: null,
    },
    suggestion: {
      id: null,
      name: null,
      productCount: null,
    },
    customized_draft: {
      customized_draft_count: null,
      customizer_id: null,
      name: null,
      product_id: null,
    },
  },
  departmentList: {
    list: [],
    commonGenderId: '',
    commonCollectionId: '',
  },
  updatedSuggestionDetails: {
    id: null,
    action: '',
  },
};

const userDefaultData = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_DRAFT_SUGGESTION: {
      let prevActiveDraft =
        SessionStorageManager.getSessionStorage('activeDraft');
      let prevCustomizerDraft = SessionStorageManager.getSessionStorage(
        'activeCustomizerDraft'
      );
      if (prevActiveDraft !== action.payload.draft.id) {
        if (!SessionStorageManager.getSessionStorage('showSelectDraft')) {
          SessionStorageManager.setSessionStorage('showSelectDraft', true);
        }

        if (action.payload.draft?.draftCount === 1) {
          SessionStorageManager.setSessionStorage('showSelectDraft', false);
        }
        SessionStorageManager.setSessionStorage(
          'activeDraft',
          action.payload.draft?.id
        );
      }
      if (prevCustomizerDraft !== action.payload.customized_draft.id) {
        if (
          !SessionStorageManager.getSessionStorage('showSelectCustomizerDraft')
        ) {
          SessionStorageManager.setSessionStorage(
            'showSelectCustomizerDraft',
            true
          );
        }

        if (action.payload.customized_draft?.customized_draft_count === 1) {
          SessionStorageManager.setSessionStorage(
            'showSelectCustomizerDraft',
            false
          );
        }
        SessionStorageManager.setSessionStorage(
          'activeCustomizerDraft',
          action.payload.customized_draft?.id
        );
      }
      return { ...state, activeDraftSuggestion: action.payload };
    }
    case SET_DEPARTMENT_LIST:
      return { ...state, departmentList: action.payload };
    case SET_UPDATE_SUGGESTION_DETAILS:
      return { ...state, updatedSuggestionDetails: action.payload };
    default:
      return state;
  }
};

export default userDefaultData;
