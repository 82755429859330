import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';

import { saveCustomizerDetails } from './actions';
import { useURLSearchParams } from 'common-hooks/url-params-listener-hooks';
import { getActiveDraftSuggestion } from 'redux/actions/global-actions';
import { useCrudOperationCart } from 'common-hooks/crud-operation-cart-hooks';

export const useProductCustomizer = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { customizerId = null, productId = null } = useURLSearchParams();
  const { addToCart } = useCrudOperationCart({});

  const saveCustomizerDetailsApi = async ({
    sections,
    isShowSnackBar = true,
  }) => {
    let payload = {
      customization_id: customizerId,
      product_id: productId,
      sections,
    };
    addToCart({ id: productId, customizer_id: customizerId });
    const res = await dispatch(
      saveCustomizerDetails({
        id: customizerId,
        payload: payload,
        isShowSnackBar: isShowSnackBar,
      })
    );
    if (res?.status) {
      await dispatch(getActiveDraftSuggestion());
      await queryClient.invalidateQueries('getCustomizerListData');
    }
    return res;
  };
  const isPartConfigured = (part) => {
    if (part.color || part.material?.value || part.text.value) return true;
    else return false;
  };
  const checkIfPartConfiguredDefault = (part) => {
    if (
      (part.color !== part?.default_color && part?.color) ||
      (part.material?.value !== part?.material?.default_material &&
        part.material?.value) ||
      part.text.value ||
      part.logo?.logo_url
    )
      return true;
    else return false;
  };

  return {
    customizerId,
    productId,
    saveCustomizerDetailsApi,
    isPartConfigured,
    checkIfPartConfiguredDefault,
  };
};
