import { useEffect, useRef, useMemo, useState } from 'react';
import { useCustomizerHandler } from 'common-hooks/customizer-handler-hooks';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  fetchCustomizerDetails,
  getCustomizerProductSummary,
} from 'pages/customization/actions';
import { useProductCustomizer } from 'pages/customization/product-customizer-hooks';
import { showSnackbar } from 'components/snackbar/actions';
import { addFontsFromCDNFile } from 'utils/add-fonts';

export const useViewModal = () => {
  const canvas = useRef();
  const [productSumaryData, setProductSumaryData] = useState();
  const [customizerDetails, setCustomizerDetails] = useState(null);

  const { customizerId, productId } = useProductCustomizer();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { initializeCustomizerCanvas, changeCameraAngle } =
    useCustomizerHandler();

  const [productCustomizer, setProductCustomizer] = useState(null);
  const [viewOptions, setViewOptions] = useState([]);

  const fetchCustomizeProductSummary = async ({ cameraAngleId }) => {
    const res = await dispatch(
      getCustomizerProductSummary({
        camerAngelId: cameraAngleId,
        cuzomizationId: customizerId,
      })
    );
    if (res) {
      setProductSumaryData(res);
    }
  };
  const fetchCustomizerData = async () => {
    const res = await dispatch(fetchCustomizerDetails(customizerId));

    if (res) {
      setCustomizerDetails(res);
      if (
        Array.isArray(res?.font_family_options) &&
        res?.font_family_options.length > 0
      ) {
        res?.font_family_options?.forEach(async (fontFamily) => {
          let fontName = fontFamily?.value;
          let fileLink = fontFamily?.link;
          const status = await addFontsFromCDNFile(fontName, fileLink);
          if (!status) {
            dispatch(
              showSnackbar({
                snackbarMessage: 'There was an error adding the fonts',
                type: 'error',
                snackbarAlign: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              })
            );
          }
        });
      }

      initializeCustomizerCanvas({
        customiserData: res,
        canvas: canvas,
        productCustomizer: productCustomizer,
        defaultCameraAngle: res?.camera_angles[0]?.value,
      });
      let selectedCamerAngle = res?.camera_angles[0]?.id;
      fetchCustomizeProductSummary({ cameraAngleId: selectedCamerAngle });
      let viewOptionsArray = res?.camera_angles?.map((angle) => {
        return {
          imgSrc: angle?.camera_image,
          id: angle?.id,
          value: angle?.value,
          label: angle?.label,
        };
      });
      setViewOptions(viewOptionsArray);
    }
  };

  useEffect(() => {
    import(
      '@myatonce/product-customizer/classes/ProductCustomizer/ProductCustomizer'
    )
      .then((module) => {
        setProductCustomizer(new module.ProductCustomizer());
      })
      .catch((error) => {
        console.error('Failed to load ProductCustomizer:', error);
      });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (customizerId && productCustomizer) fetchCustomizerData();
  }, [customizerId, productCustomizer]);

  useEffect(() => {}, []);

  return {
    canvas,
    navigate,
    fetchCustomizeProductSummary,
    productCustomizer,
    changeCameraAngle,
    productSumaryData,
    viewOptions,
    customizerDetails,
    productId,
    customizerId,
  };
};
